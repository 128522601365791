@use './auxs' as *;
// Converted Variables


// Custom Media Query Variables

.playground{
	width: 100%;
	height: 100%;
    display: flex;
	flex-direction: column;
	/* display: table-row; */
	color: color-contrast($dark);
	background-color: $dark;
	border-radius: $border-width * 2;
}

.playground-header{
	padding: ($spacer) ($grid-gutter-width - division($spacer, 2));
	position: absolute;
	width: 100%;
	z-index: 1;
}

.playground-body{
	display: inherit;
	flex: 1 1 auto;
}

.playground-container{
	@include clearfix;
	position: relative;
	width: 100%;
	max-height: 100%;
	height: 100%;
	border-radius: inherit;
	flex: 1 1 auto;
}

.playground-item{
	position: relative;
	border-radius: inherit;
    display: inherit;
    flex: inherit;
	flex-direction: column;
	// max-width: 50%;
	height: 100%;
	// overflow: hidden auto;
	background-color: inherit;
	z-index: 0;
	padding:  $grid-gutter-width;
	&:first-of-type{
		padding-top: calc-rel(90px);
		&:after{
			content: "";
			position: absolute;
			height: $border-width;
			width: 100%;
			background-color: rgba($gray-500, .1);
			right: 0;
			left: 0;
			z-index: 1;
			margin-top: -$spacer;
		}
	}

	@include media-breakpoint-up(lg){
		padding-top: calc-rel(75px);
		// height: 100vh;
		&:first-of-type{
			padding-right:  $grid-gutter-width + $spacer;
			&:after{
				width: $border-width;
				height: 100%;
				top: 0;
				left: unset;
				margin-top: unset;
			}
		}
	}
}

.playground-label{
	// position: absolute;
	top: 0;
	left: 0;
	// padding: $spacer * 3 $grid-gutter-width;
	font-size: $font-size-md;
	color: $gray-500;
	opacity: .66;
	background-color: inherit;
	width: 100%;
	border-radius: inherit;
	z-index: 1;
}

.playground-code {
    position: relative;
	word-break: break-all;
	white-space: break-spaces;
    height: inherit;
	max-height: calc(100vh - 9rem);
	overflow: auto;
	&:not(:only-child) {
		z-index: 2;
		padding-top: ($spacer * 2);
	}
	pre{
		word-break: inherit;
		white-space: inherit;
	}
}

.playground-btn{
	width: 4rem;
	height: 4rem;
	line-height: 4rem;
	position: absolute;
	left: 0;
	right: 0;
		top: -4rem;
	@include media-breakpoint-up(lg){
		top: - division(4rem, 2);
	}
	bottom: 0;
	margin: auto;
	border-radius: 4rem;
	text-align: center;
	font-family: $font-family-base;
	font-weight: 400;
	font-size: 0;
	text-transform: uppercase;
	transition: all 0.3s ease;
	z-index: 2;
	color: inherit;
	background-color: $info;
	background-size: ($spacer * 2);
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
	&:not(.disabled):hover{
		cursor: pointer;
		width: 6rem;
		height: 6rem;
		line-height: 6rem;
		background-position: 6rem center;
		font-size: $font-size-xs;
		letter-spacing: .25em;
		padding-left: $border-width * 2;
		.btn{
			opacity: 1;
		}
		.arrow svg{
			margin-left: 6rem;
			opacity: 0;
		}
	}
	.btn{
		border-color: transparent;
		opacity: 0;
		font-size: inherit;
		width: 100%;
		height: 50%;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0;
		background-repeat: no-repeat;
		transition: all 0.3s ease;
		border-radius: inherit;
		&:first-of-type{
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:last-of-type{
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&:hover{
			background-color: shift-color($info, 8%);
		}
	}
}

.playground-droparea{
    height: 100%;
    width: calc(100% - #{division($spacer,2)});
	// z-index: $zindex-droparea;
	// margin: 0 $spacer;
	transition: (opacity 300ms, display 300ms 300ms);
	// outline: $spacer solid rgba($gray-500, .05);
	box-shadow: inset 0 0 0 $spacer $dark;
	background-color: rgba($gray-500, .05);
	color: currentColor;
	border: $border-width dashed rgba($gray-500, .25);
	border-radius: $border-radius;
	text-align: center;
	.icns{
		color: rgba($gray-500, .66);
		line-height: $line-height-copy;
		font-size: $font-size-hd * 3;
	}
	&:hover,
	&:focus{
		cursor: pointer;
		// outline: $spacer solid rgba($gray-500, .05);
	}
	&:hover{
		color: $white;
		.icns{
			color: $primary;
		}
	}
}


.react-pdf__Page__canvas{
    margin: auto;
}