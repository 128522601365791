// === External Library ===
@use './auxs' as *;
@use '~bootstrap/scss/bootstrap.scss' with (
	$enable-negative-margins:			$enable-negative-margins,
	$variable-prefix:					$variable-prefix,
	// colors
	$gray-100:                          $gray-100,
	$gray-200:                          $gray-200,
	$gray-300:                          $gray-300,
	$gray-400:                          $gray-400,
	$gray-500:                          $gray-500,
	$gray-600:                          $gray-600,
	$gray-700:                          $gray-700,
	$gray-800:                          $gray-800,
	$gray-900:                          $gray-900,
	$black:                             $black,
	$blue:                              $blue,
	$indigo:                            $indigo,
	$purple:                            $purple,
	$cyan:                              $cyan,
	$teal:                              $teal,
	$green:                             $green,
	$orange:                            $orange,
	$yellow:                            $yellow,
	$red:                               $red,
	$pink:                              $pink,
	$info:                              $blue,
	$primary:                           $primary,
	$secondary:                         $secondary,
	$light:                             $gray-100,
	$dark:                              $gray-900,
	$body-color:                        $gray-800,
	$theme-colors:                      $theme-colors,

	// Global
	$min-contrast-ratio:				$min-contrast-ratio,
	$link-color:						$link-color,
	$link-hover-color:					$link-color,
	$link-decoration:                   none,
	// $yiq-contrasted-threshold:          $yiq-contrasted-threshold,
	$border-color:                      $border-color,
	$border-radius:                     $border-radius,
	$border-radius-lg:                  $border-radius-lg,
	$border-radius-sm:                  $border-radius-sm,
	$table-color:                       currentColor,

	// Typography
	$font-family-base:                  $font-family-base,
	$headings-font-family:				$headings-font-family,

	$font-size-base:                    $font-size-base,
	$font-size-lg:                      $font-size-lg,
	$font-size-sm:                      $font-size-sm,

	$line-height-base:                  $line-height-base,
	$line-height-lg:                    $line-height-lg,
	$line-height-sm:                    $line-height-sm,

	$spacers:							$spacers,
	// $hr-border-color:                   $common,

	// Forms + Button
	$input-btn-padding-y:               $input-btn-padding-y,
	$input-btn-padding-x:               $input-btn-padding-x,
	$input-btn-font-family:             $input-btn-font-family,
	$input-btn-font-size:               $input-btn-font-size,
	$input-btn-line-height:             $input-btn-line-height,

	$input-btn-padding-y-sm:            $input-btn-padding-y-sm,
	$input-btn-padding-x-sm:            $input-btn-padding-x-sm,
	$input-btn-font-size-sm:            $input-btn-font-size-sm,
	// $input-btn-line-height-sm:          $input-btn-line-height-sm,

	$input-btn-padding-y-lg:            $input-btn-padding-y-lg,
	$input-btn-padding-x-lg:            $input-btn-padding-x-lg,
	$input-btn-font-size-lg:            $input-btn-font-size-lg,
	// $input-btn-line-height-lg:          $input-btn-line-height-lg,

	$input-btn-border-width:            $input-btn-border-width,

	// Forms
	$input-bg:                          $input-bg,
	$input-group-addon-bg:              $input-group-addon-bg,
	// $input-border-color:                $input-border-color,
	// $input-focus-border-color:          $input-focus-border-color,
	$input-color:                       $input-color,
	$input-border-radius-lg:            $input-border-radius-lg,
	$btn-border-radius:                 $btn-border-radius,
	$btn-border-radius-lg:              $btn-border-radius-lg,
	$form-feedback-icon-valid:          $form-feedback-icon-valid,
	$form-validation-states:            $form-validation-states,
	$form-check-input-border:			transparent,

	// Dropdowns
	$dropdown-border-color:             $border-color,
	$dropdown-padding-y:                0,
	$dropdown-item-padding-y:           $dropdown-item-padding-y,
	$dropdown-link-color:               $gray-600,
	$dropdown-border-radius:            $dropdown-border-radius,

	// Cards
	$card-bg:                           $white,
	$card-border-color:                 $card-border-color,
	$card-spacer-x:                     $card-spacer-x,
	$card-spacer-y:                     $card-spacer-x,
	$card-cap-bg:                       transparent,
	$card-border-radius:                $card-border-radius,

	// Navs
	$nav-link-font-size:				$font-size-aux,
	$nav-link-font-weight:				$nav-link-font-weight,

	// Navbar
	$navbar-padding-y:					map-get($spacers, 2),
	$navbar-dark-color:                 rgba($white, .65),
	$navbar-dark-hover-color:           $navbar-dark-hover-color,
	// $navbar-dark-active-color:          $white,
	$grid-gutter-width:                 $grid-gutter-width,
	$grid-breakpoints:                  $grid-breakpoints,
	$container-max-widths:              $container-max-widths,

	// Modals
	$modal-content-border-radius:       0,
	$modal-content-border-color:        $border-color,
	$modal-content-color:				$modal-content-color,
	$modal-backdrop-bg:                 $light,
	$modal-backdrop-opacity:            $modal-backdrop-opacity,
	$utilities:							$utilities,
);

:root{
	font-variant-ligatures: common-ligatures;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
	-webkit-font-feature-settings: 'liga';
	-moz-font-feature-settings: 'liga';
}

:root{
	// stylelint-disable custom-property-empty-line-before
	--#{$prefix}auxs-font-family: #{$headings-font-family};
}

.db,
.block{
	display: block;
}

.block{
	position: relative;
	@include clearfix;
}

.no-focus{
	box-shadow: none !important;
	outline: none !important;
}

.no-event{
	user-select: none !important;
	pointer-events: none !important;
}

.sr-only{
	@extend .visually-hidden;
}


@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		// line-height
		.lh#{$infix}-0 						{ line-height: 0;}
		.lh#{$infix}-1 						{ line-height: 1;}
		.lh#{$infix}-copy					{ line-height: $line-height-copy !important;}
		.lh#{$infix}-title					{ line-height: $headings-line-height !important;}
		.lh#{$infix}-root					{ line-height: $font-size-base!important;}
		.lh#{$infix}-base                   { line-height: $line-height-base !important;}
		.lh#{$infix}-inherit				{ line-height: inherit !important;}
		.lh#{$infix}-unset					{ line-height: unset !important;}

		.fw#{$infix}-black					{ font-weight: 900 !important;}
		.fw#{$infix}-demi					{ font-weight: 600 !important;}
		.fw#{$infix}-medium					{ font-weight: 500 !important;}
		.fw#{$infix}-book					{ font-weight: 400 !important;}
		.fw#{$infix}-thin 					{ font-weight: 200 !important;}

		.fs#{$infix}-tiny    				{ font-size: 85%;}
		.fs#{$infix}-xs    					{ font-size: calc-rel(10px);}
		.fs#{$infix}-sm    					{ font-size: calc-rel(12px);}
		.fs#{$infix}-md    					{ font-size: calc-rel(13px);}
		.fs#{$infix}-aux					{ font-size: $font-size-aux;}
		.fs#{$infix}-book    				{ font-size: $font-size-book;}
		.fs#{$infix}-lg    					{ font-size: $font-size-lg;}
		.fs#{$infix}-xl    					{ font-size: $font-size-xl;}
		.fs#{$infix}-hd    					{ font-size: $font-size-base * 1.75;}
		.fs#{$infix}-root  					{ font-size: $font-size-base !important;}
		.fs#{$infix}-initial 				{ font-size: initial !important;}
		.fs#{$infix}-inherit 				{ font-size: inherit !important;}

		.ff#{$infix}-base					{ font-family: var(--#{$prefix}body-font-family) !important;}
		.ff#{$infix}-aux					{ font-family: var(--#{$prefix}auxs-font-family) !important;}

		.flex#{$infix}-column,
		.flex#{$infix}-row{
			display: flex;
			position: relative;
			.col#{$infix}, [class*="col#{$infix}-"]						{ position: relative; }
		}

		.flex#{$infix}-inherit											{ flex-direction: inherit !important;}
		.d#{$infix}-unset 												{ display: unset !important;}
		.d#{$infix}-inherit 											{ display: inherit !important;}
	}
}

@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color}-hover:hover {
    color: color-contrast($value) !important;
    background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) !important;
  }
}

html,
body {
    height: 100%;
}

#root,
.layout{
    display: flex;
    height: inherit;
}

.view,
.detail,
.wrapper {
    flex-direction: column;
    display: flex;
    height: 100%;
    z-index: 0;
}

.wrapper {
    flex: 1;
    max-height: -webkit-fill-available;
}
