/* --- Functions --- */
@use "sass:math";
@use "~bootstrap/scss/functions.scss";

// Units
// ====================
@function to-number($value) {
	// @ref: https://stackoverflow.com/questions/47630616/scss-arithmetic-operation-with-string
	$value: unquote('#{$value}');
	@if type-of($value) == 'number' {
		@return $value;
	}
	@else if type-of($value) != 'string' {
			@warn '#{$value} for `to-unit` should be a number or a string.';
	}

	$units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);
	$parsed-unit: false;

	@each $unit in $units {
		// str-index - find substring in a string
		// 'px' in '10px' for example

		// $unit is a pair of ['px': 1px] (item in $units)
		// nth(['px': 1px], 1) returns 'px'
		// nth(['px': 1px], 2) returns 1px

		@if (str-index($value, nth($unit, 1))) {
			$parsed-unit: nth($unit, 2);
		}
	}

	@if (not $parsed-unit) {
		@error 'Invalid unit `#{$value}`.';
	}

	@return $parsed-unit;
}

@function division($dividend, $divisor, $precision: 10) {
	@if function-exists('math.div') or function-exists('div')  {
		@return math.div($dividend, $divisor);
	}
	@else if function-exists('divide')  {
		@return divide($dividend, $divisor, $precision);
	} @else {
        // this portion was removed from https://github.com/twbs/bootstrap/blob/main/scss/_functions.scss
        $sign: if($dividend > 0 and $divisor > 0 or $dividend < 0 and $divisor < 0, 1, -1);
        $dividend: abs($dividend);
        $divisor: abs($divisor);
        @if $dividend == 0 {
            @return 0;
        }
        @if $divisor == 0 {
            @error "Cannot divide by 0";
        }
        $remainder: $dividend;
        $result: 0;
        $factor: 10;
        @while ($remainder > 0 and $precision >= 0) {
            $quotient: 0;
            @while ($remainder >= $divisor) {
            $remainder: $remainder - $divisor;
            $quotient: $quotient + 1;
            }
            $result: $result * 10 + $quotient;
            $factor: $factor * .1;
            $remainder: $remainder * 10;
            $precision: $precision - 1;
            @if ($precision < 0 and $remainder >= $divisor * 5) {
            $result: $result + 1;
            }
        }
        $result: $result * $factor * $sign;
        $dividend-unit: unit($dividend);
        $divisor-unit: unit($divisor);
        $unit-map: (
            "px": 1px,
            "rem": 1rem,
            "em": 1em,
            "%": 1%
        );
        @if ($dividend-unit != $divisor-unit and map-has-key($unit-map, $dividend-unit)) {
            $result: $result * map-get($unit-map, $dividend-unit);
        }
        @return $result;
	}
}

// @desc Remove the unit
/// Strips the units from strings
/// @param {string|number} $number - The number to remove units from.
/// @return {number} Unitless number.
/// @access public
/// @link https://github.com/nemd/sass/issues/533
@function unit-strip($value, $relate-to-rootbase: true, $root-base: if(variable-exists(root-px), $root-px, 16px)) {
	@if type-of($value)=='number'{
			$result: division($value, ($value * 0 + 1));
			@if unitless($value) or not $relate-to-rootbase{
					@return $result;
			}
			@else{
					$root: division($root-base, ($root-base * 0 + 1));
					@return division($result, $root);
			}
	}
	@else {
			@warn 'Not a number value: #{$value}';
			@return $value;
	}
}

/// Takes in an absolute unit and gives back a relative one.
/// @param {number} $value - A absolute unit.
/// @access public
/// @return {number} A relative unit based on the values of `$root-unit`, `if(variable-exists(enable-unit), $enable-unit, true)`.
@function calc-rel($value, $root-unit: if(if(variable-exists(enable-unit), $enable-unit, true), if(variable-exists(root-unit), $root-unit, rem), px), $root-base: if(variable-exists(root-px), $root-px, 16px)) {
	$root-unit: if(type-of($root-unit)=='number', unit($root-unit), $root-unit);
	$root-base: if($root-unit == 'em', 16px, $root-base);

	$unit: to-number($root-unit);

	@if if(variable-exists(enable-unit), $enable-unit, true) or if(variable-exists(enable-unit), $enable-unit, true) != false {
			@if type-of($value)=='number'{
					$value: if(
							unit($value) != unit($root-base),
							(unit-strip($value, false) * $root-base),
							$value
					);
					@if unit($value) == $root-unit {
							@return $value;
					}
					@else{
							@if not $root-unit == 'em' or not $root-unit == 'rem' or not $root-unit == '%' {
									@return null;
							}

							@if unit($value) != $root-unit {

									@if type-of($value) != 'number' or (unit($value) == '%') {
											@return $value;
									}

									@if not unitless($value) {
											$value: unit-strip($value, false);
									}

									@if not unitless($root-base) {
											$root-base: unit-strip($root-base, false);
									}

									@if($root-unit == '%') {
											@return percentage(division($value, $root-base));
									}

									@else{
											@return (division($value, $root-base) * $unit);
									}
							}

							@else {
									@return $value;
							}
					}
			}
			@else {
					@return $value;
			}
	}

	@else {
			@return $value; // ignores value
	}
}

// Strings
// ====================
@function str-replacer($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
			str-replacer(
				str-slice($string, $index + str-length($search)),
				$search,
				$replace
			);
	}
	@return $string;
}

@function font-url($value, $find: null, $rep: null) {
	$value: unquote("#{$value}");
	@if $find != null {
		$find: unquote("#{$find}");
		$rep: unquote("#{$rep}");
	}
	@if $find == null {
		$find: unquote(" ");
		$rep: unquote("+");
	}
	$result: str-replace($value, $find, $rep);
	@return unquote("#{$result}");
}

// scss-docs-start color-functions
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

// Shade the color if the weight is positive, else tint it
@function shift-color($color, $weight) {
  @return if($weight > 0, shade-color($color, $weight), tint-color($color, -$weight));
}

@function color-contrast($color) {
	@if function-exists('color-contrast')  {
		@return color-contrast($color);
	}
	@else if function-exists('color-yiq')  {
		@return color-yiq($color);
	} @else {
        @return $color;
	}
}

@mixin scrollbar($size, $foreground-color, $background-color: null, $radius: $size, $border: division($size,4)) {
    @if $background-color == null{
        $background-color: if(type-of($foreground-color) != color, $foreground-color ,rgba(color-contrast($foreground-color), .2));
    }
    &::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $background-color;
        border: $border solid if(type-of($foreground-color) != color, transparent, $foreground-color);
        border-radius: $radius;
    }

    &::-webkit-scrollbar-track {
        background: if(type-of($foreground-color) != color, transparent, $foreground-color);
        border-radius: $radius;
    }
}

@import 'bootstrap/scss/_functions.scss';

/* --- Variables --- */

// Options
// ====================
$enable-ie-compliance:			false !default;
$enable-negative-margins:		true !default;
$variable-prefix:				'px-' !default;


// Colors
// ====================
$black:                             #0b1932; // #0d0e0e;
$gray-900:                          shift-color($black, 20%); // #18181a;
$gray-800:                          shift-color($gray-900, -20%);
$gray-700:                          #4f5255;
$gray-600:                          #696d72;
$gray-500:                          #9da0a4;
$gray-400:                          #b5b8bb;
$gray-300:                          #ced0d2;
$gray-200:                          #e5e5ec;
$gray-100:                          #f4f4f7;
$white:                             #ffffff;

$blue:                              #5691ff;
$purple:                            #7137E6;
$indigo:                            #303c8b;
$cyan:                              #3db1d4;
$teal:                              #2C6369;
$green:                             #3eccbf;
$yellow:                            #ffd767;
$red:                               #f56565;
$pink:                              #d82762;
$orange:							#fb8e03;
$golden:							#fbbc03;
$stage:								#fafbfb;

$primary:                           $golden;
$secondary:                         $orange;
$accent:                         	$pink;
$market:                         	$indigo;
$light:                             $gray-100;
$dark:                              $gray-900;
$common:                        	rgba($gray-600, .085) !default;

$px-colors: (
	"accent":						$accent,
	"market":						$market,
	"issue":						$orange,
	"faded":						$gray-200,
	"stage":						$stage,
	"burn":							shade-color($dark, 10%)
) !default;

$auxs-colors: (
	"inherit":						inherit,
	"none":							transparent,
	"cc":							currentColor,
	"default":						$gray-400,
	"common":						$common,
	"mix":							rgba($black, .055),
	"dim":							rgba($black, .015)
) !default;

// Global
$yiq-contrasted-threshold:          195;
$min-contrast-ratio:				1.5;
$body-color:                        $gray-800;
$body-bg:							$white !default; // #404055 !default;
$body-text-align:					null !default;
$link-color:						currentColor !default;
$link-hover-color:					$link-color !default;

$border-color:                      transparent;
$border-width:                      calc-rel(1px);
$border-widths: (
	5: ($border-width*5),
	4: ($border-width*4),
	3: ($border-width*3),
	2: ($border-width*2),
	1: ($border-width),
) !default;

// scss-docs-start border-radius-variables
$border-radius:               calc-rel(8px);
$border-radius-sm:            division($border-radius, 2);
$border-radius-lg:            $border-radius * 2;
$border-radius-pill:          50rem;
// scss-docs-end border-radius-variables

$rounded: (
	null: $border-radius,
	0: 0,
	1: $border-radius-sm,
	2: $border-radius,
	3: $border-radius-lg,
	4: $border-radius-lg * 2,
	5: ($border-radius-lg * 2) * 2,
	circle: 50%,
	pill: $border-radius-pill
) !default;


$zindex-above: 						(1020 + 1) !default;
$zindex-golden: 					(1030 + 2) !default;
$zindex-max:						2147483647 !default;

// Layout
$grid-gutter-width:                 calc-rel(40px);
$grid-breakpoints: (
	xs:                             0,
	sm:                             calc-rel(576px, em),
	md:                             calc-rel(768px, em),
	lg:                             calc-rel(1024px, em),
	xl:                             calc-rel(1368px, em),
	hd:                             calc-rel(1480px, em)
);

$container-max-widths: (
	sm:                              calc-rel(540px, em),
	md:                              calc-rel(720px, em),
	lg:                              calc-rel(960px, em),
	xl:                              calc-rel(1280px, em),
	hd:                              calc-rel(1360px, em)
);

$spacer:							calc-rel(16px)!default;
$spacers: (
	0: 								0,
	1: 								division($spacer, 4),
	2: 								division($spacer, 2),
	3: 								$spacer,
	4: 								$spacer * 1.5,
	5: 								$spacer * 3,
	6: 								$spacer * 4,
	7: 								$spacer * 6,
	x8: 							$spacer * 8,
	gutter: 						division($grid-gutter-width, 2),
) !default;

// Typography
$root-font-base:					'PT Sans' !default;
$root-font-headings:				'Montserrat' !default;

$font-family-sans-serif:			(Helvetica, Arial, sans-serif) !default;
$font-family-serif:					('PT Serif', Georgia, "Times New Roman", Times, serif) !default;
$font-family-mono:					(Menlo, Monaco, Consolas, "Courier New", monospace) !default;
$font-family-fallback:				(-apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji") !default;

$font-family-base:					if(variable-exists('root-font-base'), ($root-font-base, $font-family-fallback), if(variable-exists(font-base), $font-base, $font-family-fallback)) !default;
$headings-font-family:				if(variable-exists('root-font-headings'), if($root-font-headings, ($root-font-headings, $font-family-fallback), null) ,if(variable-exists(font-aux), $font-aux, null)) !default;

$font-size-base:                    calc-rel(16px)!default; // Assumes the browser default, typically `16px`
$font-size-common:                  $font-size-base  - $border-width !default;
$font-size-book:					$font-size-common !default;
$font-size-aux:						calc-rel(14px) !default;
$font-size-hd:						$font-size-base * 1.5 !default;
$font-size-xl:                      $font-size-base * 1.25;
$font-size-lg:                      $font-size-base * 1.1875;
$font-size-md:                      $font-size-base * .9375;
$font-size-sm:                      $font-size-base * .875;
$font-size-xs:                      $font-size-base * .6875;

$line-height-base:                  unit-strip(26px);
$line-height-copy:                  1.4 !default;
$line-height-common:                $line-height-base * .875 !default;
$line-height-lg:                    $line-height-base * 1.125;
$line-height-md:                    $line-height-base * .75;
$line-height-sm:                    $line-height-base * .5;

$caption-line-height:				$line-height-base !default;
$caption-font-size:					calc-rel(12px) !default;
$caption-font-weight:				bolder !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:               calc-rel(10px);
$input-btn-padding-x:               ($input-btn-padding-y * 2) + ($border-width * 2);
$input-btn-font-family:             $headings-font-family;
$input-btn-font-size:               $font-size-common;
$input-btn-line-height:             $line-height-base;

$input-btn-font-size-sm:            calc-rel(13px);
$input-btn-padding-y-sm:            division($input-btn-padding-y,2);
$input-btn-padding-x-sm:            $input-btn-padding-y;

$input-btn-padding-y-lg:            ($input-btn-padding-x * .75) - $border-width;
$input-btn-padding-x-lg:            calc-rel(20px);

$input-btn-font-size-lg:            $input-btn-font-size;
$input-btn-line-height-lg:          $line-height-lg;

$input-btn-border-width:            $border-width;
$input-border-radius-lg:            $border-radius * 8;
$btn-border-radius:                 $border-radius * 8;
$btn-border-radius-lg:              $btn-border-radius;

// Forms
$input-bg:                          $common;
$input-group-addon-bg:              $input-bg;
$input-border-color:                $border-color;
$input-focus-border-color:          $input-border-color;
$input-color:                       $gray-500;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$green}' d='M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z'/><path fill='#{$green}' d='m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z'/></svg>");

// Card
$card-border-color:                 $border-color;
$card-bg:                           $common;
$card-spacer-x:                     $input-btn-padding-x;
$card-border-radius:                $border-radius-sm;

// dropdown
$dropdown-item-padding-y:           division($font-size-base, 2);
$dropdown-border-radius:            $border-radius-sm;
// $dropdown-item-padding-x:           1.5rem !default;

// Avatars
$avatar-size:                       ($grid-gutter-width + $input-btn-padding-y);
$avatar-message:                    ($avatar-size * .875) - $border-width;
// $modal-backdrop-bg:                 $light;
$modal-backdrop-opacity:            .92;
$modal-content-color:				$body-color;

@import 'bootstrap/scss/_variables.scss';

$theme-colors: map-merge($theme-colors, $px-colors);

@import 'bootstrap/scss/_maps.scss';
@import 'bootstrap/scss/_mixins.scss';

$nav-link-font-size-px:				$font-size-aux !default;
$nav-link-font-weight-px:			500 !default;

$form-feedback-warning-color:       transparent !default;
$form-feedback-icon-warning-color:  $warning !default;
$form-feedback-icon-warning:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$form-feedback-icon-warning-color}' d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'/></svg>") !default;

$form-validation-states: map-merge(
	(
		"warning": (
			"color": $form-feedback-warning-color,
			"icon": $form-feedback-icon-warning
		)
	),
	$form-validation-states
);

// Utilities
// ====================
$utilities:
  (
    "opacity": (
		property: opacity,
		class: o,
		responsive: true,
		state: hover,
		values: (
			0: 0,
			05: .05,
			11: .11,
			22: .22,
			33: .33,
			44: .44,
			50: .5,
			66: .66,
			77: .77,
			88: .88,
			99: .99,
			100: 1,
		)
    ),
    "display": (
      responsive: true,
      print: true,
      property: display,
      class: d,
      values: inline inline-block contents block grid table table-row table-cell flex inline-flex none
    ),
    "letter-spacing": (
		property: letter-spacing,
		responsive: true,
		class: ls,
		values: (
			5: ($border-width*4.5),
			4: ($border-width*3),
			3: ($border-width*1.5),
			2: ($border-width),
			1: division($border-width, 1.5),
			n1: division(-$border-width, 1.5),
			n2: -($border-width),
			n3: -($border-width*1.5),
			n4: -($border-width*3),
			n5: -($border-width*4.5),
			none: 0
		)
    ),
    "color": (
      property: color,
      class: text,
      state: hover,
      local-vars: (
        "bg-opacity": 1
      ),
      values: map-merge(
        $utilities-bg-colors,
        map-merge(
			$auxs-colors,
			("muted": $text-muted)
		)
      )
    ),
    "border-color": (
		property: border-color,
		responsive: true,
		class: border,
		state: hover,
      local-vars: (
        "bg-opacity": 1
      ),
		values: map-merge(
			$utilities-bg-colors,
			$auxs-colors,
		)
    ),
    "background-color": (
      property: background-color,
      class: bg,
      state: hover,
      local-vars: (
        "bg-opacity": 1
      ),
      values: map-merge(
        $utilities-bg-colors,
		$auxs-colors,
      )
    ),
    "z-index": (
		property: z-index,
		class: z,
		state: hover,
		values: (
			2: 2,
			1: 1,
			0: 0,
			n1: -1,
			n2: -2,
			above: $zindex-above,
			golden: $zindex-golden,
			max: $zindex-max
		)
    ),
	"hr": (
      property: height,
      class: hr,
      values: $border-widths
    ),
	"avatar": (
	  property: (width, height, line-height),
	  class: avatar,
	  values: (
		null: $avatar-size,
		1: ($font-size-base * 1.5),
		2: ($font-size-base * 2),
		3: ($font-size-base * 2.5),
		4: ($font-size-base * 3.5),
		5: ($font-size-base * 4.5),
		'btn': $avatar-size,
		'message': ($avatar-message),
		'root': ($font-size-base),
		'aux': ($font-size-aux)
	  )
	),
    "font-size": (
      rfs: true,
      property: font-size,
      class: fs,
	  responsive: true,
      values: $font-sizes
    ),
    "text-transform": (
      property: text-transform,
      class: text,
      values: lowercase uppercase capitalize none
    ),
    "rounded": (
      property: border-radius,
	  responsive: true,
      class: rounded,
      values: $rounded
    ),
	"position": (
      property: position,
	  responsive: true,
      values: static relative absolute fixed sticky
    ),
    "shadow": (
      property: box-shadow,
	  responsive: true,
      class: shadow,
      state: hover,
      values: (
        null: $box-shadow,
        sm: $box-shadow-sm,
        lg: $box-shadow-lg,
        none: none,
      )
    ),
    "overflow-x": (
      property: overflow-x,
      values: auto hidden visible scroll,
    ),
    "border-style": (
      property: border-style,
      class: border-style,
      values: dashed dotted double groove solid inherit none,
    ),
    "border-end": (
      property: border-right,
      class: border-right,
      values: (
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color),
        0: 0,
      )
    ),
    "border-start": (
      property: border-left,
      class: border-left,
      values: (
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color),
        0: 0,
      )
    ),
	"max-width": (
      property: max-width,
      class: mw,
	  responsive: true,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        85: 85%,
        100: 100%,
  		1: $spacer * 4,
  		2: $spacer * 8,
  		3: $spacer * 16,
  		4: $spacer * 24,
  		5: $spacer * 32,
  		6: $spacer * 48,
  		7: $spacer * 64,
  		8: $spacer * 80,
        auto: unset
      )
    ),
	"width": (
      property: width,
      class: w,
	  responsive: true,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        85: 85%,
        100: 100%,
  		1: division($spacer, 4),
  		2: division($spacer, 2),
  		3: $spacer,
  		4: $spacer * 1.5,
  		5: $spacer * 3,
  		6: $spacer * 4,
  		7: $spacer * 6,
  		x8: $spacer * 8,
        inherit: inherit,
        auto: auto
      )
    ),
	"height": (
      property: height,
      class: h,
	  responsive: true,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        85: 85%,
        100: 100%,
  		1: division($spacer, 4),
  		2: division($spacer, 2),
  		3: $spacer,
  		4: $spacer * 1.5,
  		5: $spacer * 3,
  		6: $spacer * 4,
  		7: $spacer * 6,
  		x8: $spacer * 8,
        inherit: inherit,
        auto: auto
      )
    ),
    "margin-end": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: map-merge($spacers, (auto: auto))
    ),
    "negative-margin-end": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: $negative-spacers
    ),
    "margin-start": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: map-merge($spacers, (auto: auto))
    ),
    "negative-margin-start": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: $negative-spacers
    ),
    "padding-end": (
      responsive: true,
      property: padding-right,
      class: pr,
      values: $spacers
    ),
    "padding-start": (
      responsive: true,
      property: padding-left,
      class: pl,
      values: $spacers
    ),
    "allsides": (
      property: (right, left, top, bottom),
      class: all,
      values: $position-values
    ),
    "start": (
      property: left,
      class: left,
      values: map-merge($position-values, (in: 200%, out: -200%))
    ),
    "end": (
      property: right,
      class: right,
      values: map-merge($position-values, (in: 200%, out: -200%))
    ),
    "float": (
      responsive: true,
      property: float,
      values: (
        left: left,
        right: right,
        none: none,
      )
    ),
    "text-align": (
      responsive: true,
      property: text-align,
      class: text,
      values: (
        left: left,
        right: right,
        center: center,
      )
    ),
);